import React from "react";
import { animateScroll } from "react-scroll";
import { Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import UserTestimonials from "../UserTestimonials";
import ServicesOverview from "../ServicesOverview";
import HeroBanner from "components/HeroBanner";
import FAQs from "components/FAQs";
import ContactUs from "../ContactUs";
import HowItWorks from "components/HowItWorks";

const Home: React.FC = () => {
  return (
    <main>
      <Button
        color="primary"
        variant="contained"
        aria-label={"Back to top"}
        onClick={() => animateScroll.scrollToTop()}
        className="fixed bottom-5 right-5 z-50 rounded-full"
      >
        <ArrowUpwardIcon />
      </Button>
      <HeroBanner />
      <ServicesOverview />
      <HowItWorks />
      <FAQs />
      <UserTestimonials />
      <ContactUs />
    </main>
  );
};

export default Home;
