import React, {useEffect} from "react";
import {openInSameTab} from "../../utils";
import {MIZARU_APP_URL} from "../../utils/constants";

const Redirection = () => {

    useEffect(()=> {
        openInSameTab(MIZARU_APP_URL)
    }, [])

    return(
        <div>

        </div>
    )
}

export default Redirection
