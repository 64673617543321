import React from "react";
import cn from "classnames";

import { Grid, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/system";
import { rem } from "polished";
import Carousel from "react-material-ui-carousel";
import useGlobalStyles from "theme/useGlobalStyles";

import Quotes from "assets/svgs/quotes.svg";
import BridgesClient from "assets/svgs/bridges.svg";
import DbctxClient from "assets/svgs/dbctx.svg";
import { userTestimonials } from "./userTestimonials.data";
import { UserTestimonialProps } from "./userTestimonials.types";

const UserTestimonialsCard = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: rem("30px"),
  maxWidth: rem("350px"),
  backgroundColor: theme.palette.background.paper,
  borderRadius: rem("5px"),
}));

const UserTestimonials = () => {
  const isMatch = useMediaQuery(useTheme().breakpoints.down("lg"));
  const globalStyles = useGlobalStyles();

  return (
    <>
      {!isMatch && (
        <section>
          <div className="px-24 pt-9 mb-10 flex items-center justify-center">
            <h2
              className={cn(globalStyles.font24, globalStyles.secondaryText700)}
            >
              PARTNERSHIP
            </h2>
          </div>
          <div className="px-24 pb-9 flex items-center justify-center">
            <Grid container spacing={2}>
              <Grid item md={6} lg={6}>
                <img
                  height={"149px"}
                  width={"100%"}
                  src={DbctxClient}
                  alt="DBCTX CLIENT Partnership Logo"
                />
              </Grid>
              <Grid item md={6} lg={6}>
                <img
                  height={"149px"}
                  width={"100%"}
                  src={BridgesClient}
                  alt="Bridges Client Partnership Logo"
                />
              </Grid>
            </Grid>
          </div>
        </section>
      )}
      <section className="flex flex-col items-center justify-center">
        <div className="px-24 py-9 flex items-center justify-center text-center">
          <h1 className={cn(globalStyles.font48, globalStyles.PrimaryText700)}>
            Why people love Mizaru ?
          </h1>
        </div>
        {isMatch ? (
          <Carousel sx={{ width: rem("320px") }}>
            {userTestimonials.map(
              (testimonial: UserTestimonialProps, index: number) => (
                <UserTestimonialsCard key={index}>
                  <div className="my-5">
                    <img
                      src={Quotes}
                      alt="quotes-logo"
                      width="60px"
                      height="30px"
                    />
                  </div>
                  <Typography
                    className={cn(
                      globalStyles.secondaryText400,
                      globalStyles.font17
                    )}
                    dangerouslySetInnerHTML={{
                      __html: testimonial.content,
                    }}
                  ></Typography>
                  <Typography
                    className={cn(
                      globalStyles.font17,
                      globalStyles.PrimaryText700,
                      "mt-8"
                    )}
                  >
                    {testimonial.provider}
                  </Typography>
                </UserTestimonialsCard>
              )
            )}
          </Carousel>
        ) : (
          <div className="px-24 py-9 flex flex-col items-center justify-center">
            <Grid container spacing={4}>
              {userTestimonials.map(
                (testimonials: UserTestimonialProps, index: number) => (
                  <Grid item key={index} xs={12} md={6}>
                    <UserTestimonialsCard>
                      <div className="my-5">
                        <img
                          src={Quotes}
                          alt="quotes-logo"
                          width="60px"
                          height="30px"
                        />
                      </div>
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font17
                        )}
                        dangerouslySetInnerHTML={{
                          __html: testimonials.content,
                        }}
                      />

                      <Typography
                        className={cn(
                          globalStyles.font17,
                          globalStyles.PrimaryText700,
                          "mt-8"
                        )}
                      >
                        {testimonials.provider}
                      </Typography>
                    </UserTestimonialsCard>
                  </Grid>
                )
              )}
            </Grid>
          </div>
        )}
      </section>
    </>
  );
};

export default UserTestimonials;
