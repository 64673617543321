import { createTheme, responsiveFontSizes } from "@mui/material";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxs: true;
    fs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    "2xl": true;
    "3xl": true;
  }
}

let theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      fs: 560,
      xs: 640,
      sm: 768,
      md: 968,
      lg: 1024,
      xl: 1165,
      "2xl": 1280,
      "3xl": 1536,
    },
  },
  direction: "ltr",
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "#ffffff",
          boxShadow: "none",
          borderBottom: "0.5px solid #00000066",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          padding: "8px 24px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "12px 22px",
          boxShadow: "none",
          borderRadius: "8px",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "Inter, Helvetica, Arial, sans-serif",
    fontSize: 16,
  },
  palette: {
    common: {
      black: "#111111",
      white: "#ffffff",
    },
    primary: {
      main: "#ff5a5f",
    },
    background: {
      paper: "#FFF4F5",
    },
    text: {
      primary: "#111111",
      secondary: "#00000099",
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
