import React from "react";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { render } from "react-dom";

import theme from "theme";
import App from "./App";

import "./index.css";

const cache = createCache({
  key: "css",
  prepend: true,
});

render(
  <StyledEngineProvider injectFirst>
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </CacheProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);
