import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { rem } from "polished";

const useGlobalStyles = makeStyles(
  (theme: Theme) => ({
    PrimaryText700: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontStyle: "normal",
      color: theme.palette.text.primary,
    },
    primaryText600: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontStyle: "normal",
      color: theme.palette.text.primary,
    },
    primaryText500: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontStyle: "normal",
      color: theme.palette.text.primary,
    },
    primaryText400: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontStyle: "normal",
      color: theme.palette.text.primary,
    },
    secondaryText700: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontStyle: "normal",
      color: theme.palette.text.secondary,
    },
    secondaryText600: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontStyle: "normal",
      color: theme.palette.text.secondary,
    },
    secondaryText500: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontStyle: "normal",
      color: theme.palette.text.secondary,
    },
    secondaryText400: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontStyle: "normal",
      color: theme.palette.text.secondary,
    },

    font48: {
      fontSize: rem("48px"),
      lineHeight: "1.21",

      [theme.breakpoints.down("fs")]: {
        fontSize: rem("24px"),
        lineHeight: "1.21",
      },
    },

    font32: {
      fontSize: rem("32px"),
      lineHeight: "1.33",

      [theme.breakpoints.down("fs")]: {
        fontSize: rem("24px"),
        lineHeight: "1.21",
      },
    },

    font24: {
      fontSize: rem("24px"),
      lineHeight: "1.75",

      [theme.breakpoints.down("fs")]: {
        fontSize: rem("20px"),
        lineHeight: "1.75",
      },
    },

    font20: {
      fontSize: rem("20px"),
      lineHeight: "1.2",

      [theme.breakpoints.down("fs")]: {
        fontSize: rem("17px"),
        lineHeight: "1.75",
      },
    },

    font17: {
      fontSize: rem("17px"),
      lineHeight: "1.45",
    },

    font14: {
      fontSize: rem("14px"),
      lineHeight: "1.21",

      [theme.breakpoints.down("fs")]: {
        fontSize: rem("10px"),
        lineHeight: "1.75",
      },
    },

    font12: {
      fontSize: rem("12px"),
      lineHeight: "1.83",
    },
  }),
  { index: 1 }
);

export default useGlobalStyles;
