import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/system";
import { styled } from "@mui/material/styles";
import { rem } from "polished";
import cn from "classnames";
import { OutlinedButton } from "../Button/OutlinedButton";
import useGlobalStyles from "theme/useGlobalStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ReadMoreModal from "../Modals/ReadMoreModal";
import { ServiceInterface } from "./serviceOverview.types";
import { ServicesList } from "./servicesOverview.data";

const useStyles = makeStyles(() => ({
  cardBackground: {
    "&:hover": {
      border: "0px",
      boxShadow: "5px 10px 30px rgba(0, 0, 0, 0.1)",
    },
  },
}));

const ServicesOverviewCard = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: rem("30px"),
  maxWidth: rem("350px"),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  border: "1px solid black",
  borderRadius: rem("8px"),
  height: "380px",
  width: "392px",
}));

const ServicesOverview = () => {
  const theme = useTheme(); //using MUI Breakpoints
  const isMatch = useMediaQuery(theme.breakpoints.down("sm")); //using Breakpoints as mediaquery
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  const [openReadMore, setOpenReadMore] = useState(false);
  const [openService, setOpenService] = useState(0);

  const openReadMoreModal = (id: number) => {
    setOpenReadMore(true);
    setOpenService(id);
  };

  const handleModalClose = () => {
    setOpenReadMore(false);
  };

  return (
    <section id="services-section">
      <div className="px-24 py-9 flex items-center text-center">
        <h1
          className={cn(globalStyles.font48)}
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          Services
        </h1>
      </div>
      <div>
        {isMatch || isTablet ? (
          <>
            <div className="padding-15 flex items-center text-center">
              <Grid container spacing={2}>
                {ServicesList.map(
                  (service: ServiceInterface, index: number) => (
                    <Grid
                      item
                      key={index}
                      xs={12}
                      md={4}
                      sx={{ borderRadius: "8px" }}
                      className={"flex pt-0"}
                    >
                      <div className="pb-15">
                        <Accordion className="accordion_base">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Box
                              component="img"
                              className="image_mob"
                              src={service.icon}
                              alt={service["alt-text"]}
                            />
                            <Typography
                              gutterBottom
                              // component="div"
                              className="px-15 title_flex"
                            >
                              <b>{service.title}</b>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              style={{ color: theme.palette.text.secondary }}
                              dangerouslySetInnerHTML={{
                                __html: service.content,
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Grid>
                  )
                )}
              </Grid>
            </div>
          </>
        ) : (
          <div className="px-24 py-9 flex items-center text-center">
            <Grid container spacing={2}>
              {ServicesList.map((service: ServiceInterface, index: number) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  md={4}
                  className={"flex pt-0 card_parent"}
                >
                  <ServicesOverviewCard className={classes.cardBackground}>
                    <div className={"service-overview-card-img"}>
                      <img
                        src={service.icon}
                        alt={service["alt-text"]}
                        height={service.height}
                        style={{
                          padding: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        className={"flex-start"}
                      />
                    </div>

                    <Typography
                      gutterBottom
                      variant="h5"
                      className={cn(
                        globalStyles.font24,
                        globalStyles.PrimaryText700,
                        "mx-5"
                      )}
                      component="div"
                    >
                      {service.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={cn(globalStyles.font17, "mx-5", "mb-2")}
                      style={{ color: theme.palette.text.secondary }}
                      dangerouslySetInnerHTML={{
                        __html:
                          service.content && service.content.length > 100
                            ? service.content.substring(0, 100) + " ..."
                            : service.content,
                      }}
                    ></Typography>
                    {service.content.length > 100 ? (
                      <Grid item>
                        <Grid
                          // item
                          className="lg:w-auto w-full read-more-button"
                        >
                          <OutlinedButton
                            variant="outlined"
                            className="text-black flex-end"
                            fullWidth
                            onClick={() => openReadMoreModal(index)}
                          >
                            Read More
                          </OutlinedButton>
                        </Grid>
                      </Grid>
                    ) : null}
                  </ServicesOverviewCard>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
      {openReadMore && (
        <Dialog
          open={openReadMore}
          onClose={handleModalClose}
          aria-labelledby="Read More"
          aria-describedby={"Read More" + ServicesList[openService].title}
        >
          <ReadMoreModal
            handleModalClose={handleModalClose}
            selectedService={ServicesList[openService]}
          />
        </Dialog>
      )}
    </section>
  );
};

export default ServicesOverview;
