import React, { useState } from "react";

import cn from "classnames";
import { Button, Theme, IconButton, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";
import { Link as ReactScrollLink } from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";

import { animateScroll } from "react-scroll";
import brandLogo from "assets/images/favicon_mizaru.png";
import useGlobalStyles from "theme/useGlobalStyles";
import Menu from "components/Menu";
import { NavbarProps } from "./Navbar.types";
import { openInNewTab } from "utils/index";
import { MIZARU_APP_URL } from "utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  navbarcolor: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Buttonslabel = ["Services", "How it works", "FAQs", "Contact us"];

const Navbar: React.FC<NavbarProps> = ({ isLanding }) => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleBrandLogoClick = () => {
    animateScroll.scrollToTop();
  };

  return (
    <header>
      <nav
        className={cn(
          isLanding && classes.navbarcolor,
          isLanding && "fixed w-full z-50"
        )}
      >
        <div className="xl:px-20 xl:py-8 p-5 w-full flex items-center justify-between">
          <Box onClick={handleBrandLogoClick} sx={{ cursor: "pointer" }}>
            <Link to="/">
              <img
                src={brandLogo}
                alt="mizaru-logo"
                width="156px"
                height="47px"
              />
            </Link>
          </Box>
          <div className="hidden lg:flex items-center justify-between lg:ml-16 w-full">
            <div>
              {Buttonslabel.map((label: string, index: number) => (
                <ReactScrollLink
                  key={index}
                  delay={100}
                  offset={-120}
                  to={`${label.toLowerCase().replaceAll(" ", "-")}-section`}
                  spy={true}
                  smooth={true}
                >
                  <Button
                    className={cn(
                      globalStyles.primaryText600,
                      globalStyles.font17,
                      "mx-1"
                    )}
                    onClick={() => navigate("/")}
                    sx={{ "&:hover": { backgroundColor: "#FFE8EA" } }}
                    variant="text"
                  >
                    {label}
                  </Button>
                </ReactScrollLink>
              ))}
            </div>
            {isLanding && (
              <div>
                <Link to="signup" target="_blank">
                  <Button
                    className={cn(
                      globalStyles.primaryText600,
                      globalStyles.font17,
                      "mx-1"
                    )}
                    sx={{ "&:hover": { backgroundColor: "#FFE8EA" } }}
                    variant="text"
                    aria-label={"Sign Up"}
                  >
                    Sign Up
                  </Button>
                </Link>

                <Button
                  className={cn(
                    globalStyles.primaryText600,
                    globalStyles.font17,
                    "mx-1",
                    "text-white"
                  )}
                  variant="contained"
                  color="primary"
                  aria-label={"Login"}
                  onClick={() => openInNewTab(MIZARU_APP_URL)}
                >
                  Login
                </Button>
              </div>
            )}
          </div>
          <IconButton className="lg:hidden" onClick={() => setOpen(true)}>
            <MenuIcon />
          </IconButton>
        </div>
      </nav>
      <Menu open={open} setOpen={setOpen} />
    </header>
  );
};

export default Navbar;
