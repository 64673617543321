import React from "react";

import Footer from "components/Footer";
import Navbar from "components/Navbar";
import { useLocation } from "react-router-dom";

interface Layoutprops {
  children: React.ReactNode;
}

const Layout: React.FC<Layoutprops> = ({ children }) => {
  const location = useLocation();

  return (
    <React.Fragment>
      <Navbar isLanding={location.pathname === "/"} />
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
