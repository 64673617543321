import React from "react";

import { rem } from "polished";
import cn from "classnames";

import { styled } from "@mui/material/styles";
import LinkedInLogo from "@mui/icons-material/LinkedIn";
import FacebookLogo from "@mui/icons-material/Facebook";
import { Grid, IconButton, Typography } from "@mui/material";

import useGlobalStyles from "theme/useGlobalStyles";

import { FACEBOOK_URL, LINKEDIN_URL } from "utils/constants";

import brandLogo from "assets/svgs/brand-logo.svg";
import { useLocation } from "react-router-dom";
import { openInNewTab } from "utils";

const FooterContainer = styled("section")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  minHeight: rem("317px"),
}));

const Footer: React.FC = () => {
  const globalStyles = useGlobalStyles();
  const location = useLocation();

  return (
    <footer className={location.pathname !== "/" ? "hidden lg:block" : ""}>
      <FooterContainer className="flex sm:flex-row flex-col items-center lg:px-44 p-5 lg:justify-between justify-center ">
        <div className="flex flex-col sm:items-baseline items-center sm:justify-start justify-center mb-12 sm:mb-0 sm:mr-8">
          <img
            src={brandLogo}
            alt="mizaru brand logo"
            className="pb-4"
            width="210px"
            height="64px"
            aria-label="mizaru brand logo"
          />
          <Typography
            className={cn(
              globalStyles.secondaryText400,
              globalStyles.font12,
              "text-center sm:text-left"
            )}
          >
            An online platform helping organizations find {"\n"} and manage
            disability support workers
          </Typography>
        </div>
        <Grid
          container
          className="sm:justify-start sm:flex-row flex-col justify-center sm:w-96 w-auto"
        >
          <Grid item sm={6}>
            <Grid
              container
              spacing={2}
              className="justify-center sm:justify-start flex-col sm:flex-row items-center mb-8 sm:mb-0"
            >
              <Typography
                className={cn(
                  globalStyles.PrimaryText700,
                  globalStyles.font20,
                  "sm:mb-8 mb-2"
                )}
              >
                Stay in touch
              </Typography>
              <div className="ml-1">
                <IconButton
                  onClick={() => openInNewTab(LINKEDIN_URL)}
                  role={"button"}
                  aria-label={"LinkedIn Link"}
                >
                  <LinkedInLogo fontSize="large" htmlColor="#ff5a5f" />
                </IconButton>
                <IconButton
                  onClick={() => openInNewTab(FACEBOOK_URL)}
                  role={"button"}
                  aria-label={"Facebook Link"}
                >
                  <FacebookLogo fontSize="large" htmlColor="#ff5a5f" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Grid
              container
              spacing={2}
              className="justify-center sm:justify-start flex-col sm:flex-row items-center"
            >
              <Typography
                className={cn(
                  globalStyles.PrimaryText700,
                  globalStyles.font20,
                  "sm:mb-12 mb-2"
                )}
              >
                Email us
              </Typography>
              <a
                href="mailto:support@mizaru.io"
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-red-600"
              >
                support@mizaru.io
              </a>
            </Grid>
          </Grid>
        </Grid>
      </FooterContainer>
      <div className="px-5 block sm:hidden bg-background w-full">
        <div className="h-[1px] bg-black"></div>
      </div>
      <section className="lg:px-44 p-5 flex flex-col-reverse sm:flex-row items-center justify-between bg-background sm:bg-white">
        <Typography
          className={cn(
            globalStyles.secondaryText500,
            globalStyles.font14,
            "mt-3 sm:mt-0"
          )}
        >
          © {new Date().getFullYear()} Mizaru LLC All rights reserved
        </Typography>
        <div className="flex justify-around">
          <Typography
            className={cn(
              globalStyles.primaryText600,
              globalStyles.font14,
              "mx-4 cursor-pointer"
            )}
          >
            Code of Conduct
          </Typography>
          <Typography
            className={cn(
              globalStyles.primaryText600,
              globalStyles.font14,
              "mx-4 cursor-pointer"
            )}
          >
            Privacy Policy
          </Typography>
          <Typography
            className={cn(
              globalStyles.primaryText600,
              globalStyles.font14,
              "mx-4 cursor-pointer"
            )}
          >
            Terms & Conditions
          </Typography>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
