import React from "react";

import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

import { rem } from "polished";
import cn from "classnames";

import { OutlinedButton } from "components/Button/OutlinedButton";
import useGlobalStyles from "theme/useGlobalStyles";
import {
  SUPPORT_PROVIDER_URL,
  LOOKING_FOR_SUPPORT_URL,
  MIZARU_APP_URL,
} from "utils/constants";
import { openInNewTab } from "../../utils";

const SignupContainer = styled("section")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const SignupCenterDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  width: rem("608px"),
  margin: "80px auto",
  marginBottom: 0,
  boxShadow: "5px 10px 30px 0px #0000001A",

  [theme.breakpoints.down("lg")]: {
    boxShadow: "none",
    margin: "0 auto",
    width: "100%",
  },
}));

const SignupContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  padding: rem("90px"),
  paddingBottom: 0,

  [theme.breakpoints.down("lg")]: {
    padding: `0 ${rem("20px")}`,
    width: "100%",
  },
}));

const Signup: React.FC = () => {
  const globalStyles = useGlobalStyles();

  return (
    <main>
      <SignupContainer>
        <SignupCenterDiv>
          <SignupContainerDiv>
            <h2
              className={cn(
                globalStyles.PrimaryText700,
                globalStyles.font32,
                "lg:mb-4 mb-2"
              )}
            >
              Welcome to Mizaru
            </h2>
            <h3
              className={cn(
                globalStyles.secondaryText400,
                globalStyles.font24,
                "lg:mb-8 mb-10"
              )}
            >
              Join the Mizaru community today
            </h3>
            <a
              href={LOOKING_FOR_SUPPORT_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OutlinedButton className="mb-3 lg:mb-4 py-3 lg:py-4 px-9">
                <Typography
                  className={cn(
                    globalStyles.primaryText600,
                    globalStyles.font24
                  )}
                >
                  I'm looking for support
                </Typography>
              </OutlinedButton>
            </a>
            <a
              href={SUPPORT_PROVIDER_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OutlinedButton className="mb-5 lg:mb-14 py-3 lg:py-4 ">
                <Typography
                  className={cn(
                    globalStyles.primaryText600,
                    globalStyles.font24
                  )}
                >
                  I want to provide support
                </Typography>
              </OutlinedButton>
            </a>
          </SignupContainerDiv>
          <Typography
            className={cn(
              globalStyles.secondaryText400,
              globalStyles.font17,
              "lg:mb-20 lg:block hidden"
            )}
            style={{ marginBottom: "40px" }}
          >
            By creating an account, you agree to Mizaru’s{" "}
            <span
              className={cn(
                globalStyles.secondaryText400,
                globalStyles.font17,
                "underline cursor-pointer"
              )}
            >
              Terms
            </span>
          </Typography>
        </SignupCenterDiv>
        <div
          className="flex justify-between lg:my-24 mb-8"
          style={{ marginTop: "30px" }}
        >
          <Typography
            className={cn(
              globalStyles.secondaryText400,
              globalStyles.font24,
              "lg:mr-20 mr-6"
            )}
          >
            Already have an account?
          </Typography>
          <Typography
            className={cn(
              globalStyles.secondaryText400,
              globalStyles.font24,
              "underline cursor-pointer"
            )}
            onClick={() => openInNewTab(MIZARU_APP_URL)}
          >
            Login
          </Typography>
        </div>

        <div className="lg:hidden">
          <Typography
            className={cn(
              globalStyles.secondaryText400,
              globalStyles.font12,
              "text-center"
            )}
          >
            By creating an account, you agree to
          </Typography>
          <Typography
            className={cn(
              globalStyles.secondaryText400,
              globalStyles.font12,
              "text-center"
            )}
          >
            Mizaru's{" "}
            <span
              className={cn(
                globalStyles.secondaryText400,
                globalStyles.font12,
                "underline cursor-pointer"
              )}
            >
              Terms
            </span>
          </Typography>
        </div>
      </SignupContainer>
    </main>
  );
};

export default Signup;
