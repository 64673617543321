import { UserTestimonialProps } from "./userTestimonials.types";

export const userTestimonials: Array<UserTestimonialProps> = [
  {
    content:
      "<p>I can go online and make a request and Mizaru will take care of the rest for me… I feel secure and confident that <b>I can find the right support anytime I need.</b></p>",
    provider: "A DeafBlind customer",
  },
  {
    content:
      "<p>I do not have to hunt for [requests] in my emails... <b>Just log in and VOILA… And I get paid on time!</b> I don’t have to do time sheets or anything. That’s a HUGE bonus</p>",
    provider: "A CN support worker",
  },
];
