import React from "react";

import { styled } from "@mui/material/styles";
import { Button, Grid, Typography } from "@mui/material";
import useGlobalStyles from "theme/useGlobalStyles";
import cn from "classnames";

import { OutlinedButton } from "components/Button/OutlinedButton";

import heroBannerAvatar from "assets/svgs/hero-image.svg";
import {
  LOOKING_FOR_SUPPORT_URL,
  SUPPORT_PROVIDER_URL,
} from "../../utils/constants";

const HeroBannerContainer = styled("section")(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.background.paper,
}));

const HeroBanner: React.FC = () => {
  const globalStyles = useGlobalStyles();

  return (
    <HeroBannerContainer className="lg:pl-40 px-6 pt-20">
      <Grid container>
        <Grid item md={6} className="lg:mt-20 mt-4">
          <Grid container>
            <Grid item>
              <Typography
                className={cn(
                  "sm:",
                  globalStyles.PrimaryText700,
                  globalStyles.font48
                )}
              >
                Matching support workers {"\n"} for people with disabilities can
                be easier and faster
              </Typography>
            </Grid>
            <Grid item className="mt-8">
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font24
                )}
              >
                Mizaru is an online platform helping organizations find and
                manage disability support workers
              </Typography>
            </Grid>
            <Grid item className="mt-20">
              <Grid container justifyContent="center">
                <Grid item className="mb-5 xs:mr-5 xs:w-auto w-full">
                  <a
                    href={LOOKING_FOR_SUPPORT_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      style={{ minWidth: "260px" }}
                    >
                      I’m looking for support
                    </Button>
                  </a>
                </Grid>
                <Grid item className="mb-5 xs:mr-5 xs:w-auto w-full">
                  <a
                    href={SUPPORT_PROVIDER_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <OutlinedButton
                      variant="outlined"
                      fullWidth
                      className="text-black"
                      style={{ minWidth: "260px" }}
                    >
                      I want to provide support
                    </OutlinedButton>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container alignItems="center">
            <img
              src={heroBannerAvatar}
              alt="Banner Avatar"
              className="w-full h-auto"
            />
          </Grid>
        </Grid>
      </Grid>
    </HeroBannerContainer>
  );
};

export default HeroBanner;
