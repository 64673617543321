declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ACTION = "submit";

const loadReCaptcha = (siteKey: string) => {
  const script = document.createElement("script");
  script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`;
  document.body.appendChild(script);
};

export class ReCaptcha {
  siteKey: string;
  action: string;

  constructor(siteKey: string, action: string) {
    loadReCaptcha(siteKey);
    this.siteKey = siteKey;
    this.action = action;
  }

  async getToken(): Promise<string> {
    let token = "";
    await window.grecaptcha
      .execute(this.siteKey, { action: this.action })
      .then((res: string) => {
        token = res;
      });
    return token;
  }
}

export const contactusInitValue = {
  "first-Name": {
    value: "",
    error: null,
    required: true,
  },
  "last-Name": {
    value: "",
    error: null,
    required: true,
  },
  email: {
    value: "",
    error: null,
    required: true,
  },
  topic: {
    value: "",
    error: null,
    required: true,
  },
  subject: {
    value: "",
    error: null,
    required: true,
  },
  comment: {
    value: "",
    error: null,
    required: false,
  },
};
