export const LINKEDIN_URL =
  "https://www.linkedin.com/company/mizaru-connect/?viewAsMember=true";

export const FACEBOOK_URL = "https://www.facebook.com/mizaruconnect";

export const SUPPORT_PROVIDER_URL =
  "https://mizaru.worksuite.com/job-openings/nsnou-3-2";

export const LOOKING_FOR_SUPPORT_URL =
  "https://mizaru.worksuite.com/job-openings/nsnou-3-4";

export const MIZARU_APP_URL = "https://app.mizaru.io/";
