import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import cn from "classnames";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import useGlobalStyles from "theme/useGlobalStyles";

interface FAQAccordianProps {
  page: number;
}

const FAQAccordian: React.FC<FAQAccordianProps> = ({ page }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const globalStyles = useGlobalStyles();

  return (
    <Box className="md:px-40 px-3 mt-32">
      {page === 0 && (
        <React.Fragment>
          <Accordion
            disableGutters
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel1" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                Why Mizaru?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                You can access the largest network of freelance support workers
                of diverse backgrounds and experience so that you can provide
                accommodation to your clients. You can easily manage all the
                support requests in one place, with booking, planning, and
                billing at your fingertips.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel2" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                What kind of support is available for me?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                We currently offer three kinds of support, including the
                Communication Facilitator (CF), CoNavigator (CN), and Sign
                Language Interpreter (SLI).
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel3" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                How to get started?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                1. Click the “I’m looking for support” button and create your
                account. {"\n"}
                2. Provide details about your clients and what kind of support
                they need. {"\n"}
                3. Connect and match with verified support workers and build
                your support team.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel4" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel4bh-content"
              id="panel41bh-header"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                How can I pay?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                You will receive an invoice every month with details of all the
                support requests that have been completed.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel4" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                How can I track the status of my support requests?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                We will track the status of your support requests for you. You
                will receive real-time notification when a status updates and a
                monthly report with all the support details.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      )}
      {page === 1 && (
        <React.Fragment>
          <Accordion
            disableGutters
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel1" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                How can I get paid?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                You will receive a direct deposit at the end of every month for
                all the hours you worked in the previous month. A 3% service fee
                will be deducted from your monthly payment.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel2" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                Do I need a certification?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                You must have a certification if you provide disability support
                service.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel3" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                How can I apply?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                1. Click the “I want to provide support” button and create your
                account. {"\n"}
                {"\n"}
                2. Complete the screening process and sign the required
                paperwork {"\n"}
                {"\n"}
                3. Complete the required training. {"\n"}
                {"\n"}
                Once you’ve completed these three simple steps, you can start to
                find work that suits your schedule. We’ll help you get started
                with an online introduction and walk you through the steps to
                get your first assignment.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel4" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel4bh-content"
              id="panel4bh-header1"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                How do I get matched?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                Once you’ve activated your account, you can start reviewing and
                accepting work that suits your schedule.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel4" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel4bh-content"
              id="panel4bh-header2"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                Am I required to get vaccinated?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                All disability support workers providing in-person support must
                be fully vaccinated against COVID-19.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel4" ? (
                  <RemoveIcon htmlColor="#111111" />
                ) : (
                  <AddIcon htmlColor="#111111" />
                )
              }
              aria-controls="panel4bh-content"
              id="panel4bh-header3"
            >
              <Typography
                className={cn(globalStyles.primaryText400, globalStyles.font20)}
              >
                How do we track my working hours?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={cn(
                  globalStyles.secondaryText400,
                  globalStyles.font17
                )}
              >
                We will track all your working hours, so you don’t need to
                submit any timesheets.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      )}
    </Box>
  );
};

export default FAQAccordian;
