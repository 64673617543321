import React from "react";

import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled, Theme, useTheme } from "@mui/material/styles";

import cn from "classnames";
import { rem } from "polished";
import useGlobalStyles from "theme/useGlobalStyles";
import { a11yProps, TabPanel } from "components/Tabs";

import { ReactComponent as RequestIcon } from "assets/svgs/request.svg";
import { ReactComponent as MatchIcon } from "assets/svgs/match.svg";
import { ReactComponent as CommunicateIcon } from "assets/svgs/communicate.svg";
import { ReactComponent as ServiceIcon } from "assets/svgs/service.svg";
import { ReactComponent as ReviewIcon } from "assets/svgs/review.svg";

import RequestAvatar from "assets/svgs/request-avatar.svg";
import MatchAvatar from "assets/svgs/match-avatar.svg";
import CommunicateAvatar from "assets/svgs/communicate-avatar.svg";
import ServiceAvatar from "assets/svgs/service-avatar.svg";
import ReviewAvatar from "assets/svgs/review-avatar.svg";
import ListArrow from "assets/svgs/list-arrow.svg";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  active: {
    color: theme.palette.primary.main,
    fontSize: rem(22),
    fontWeight: 500,
    marginRight: rem(12),
  },
  numbers: {
    fontSize: rem(22),
    fontWeight: 500,
    marginRight: rem(12),
  },
  fontMainColor: {
    color: theme.palette.primary.main,
  },
}));

const HowItWorksContainer = styled("section")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: `${rem("148px")} auto`,
  marginBottom: `${rem("50px")}`,
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    margin: `${rem("50px")} auto`,
  },
}));

const HowItWorks = () => {
  const globalStyles = useGlobalStyles();
  const [value, setValue] = React.useState(0);
  const isMatch = useMediaQuery(useTheme().breakpoints.down("lg"));
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <HowItWorksContainer id="how-it-works-section">
      <h1 className={cn(globalStyles.PrimaryText700, globalStyles.font48)}>
        How it works?
      </h1>
      {!isMatch ? (
        <Box className="w-full mt-12">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="how it works tabs"
            centered
          >
            <Tab
              label="1. Request"
              icon={<RequestIcon aria-label={"request icon"} />}
              {...a11yProps(0)}
              sx={{
                textTransform: "capitalize",
                padding: `16px ${rem("32px")}`,
              }}
              className={cn(globalStyles.PrimaryText700, globalStyles.font24)}
            />
            <Tab
              label="2. Match"
              icon={<MatchIcon aria-label={"match icon"} />}
              {...a11yProps(1)}
              sx={{
                textTransform: "capitalize",
                padding: `16px ${rem("32px")}`,
              }}
              className={cn(globalStyles.PrimaryText700, globalStyles.font24)}
            />
            <Tab
              label="3. Communicate"
              icon={<CommunicateIcon aria-label={"communicate icon"} />}
              {...a11yProps(2)}
              sx={{
                textTransform: "capitalize",
                padding: `16px ${rem("32px")}`,
              }}
              className={cn(globalStyles.PrimaryText700, globalStyles.font24)}
            />
            <Tab
              label="4. Service"
              icon={<ServiceIcon aria-label={"service icon"} />}
              {...a11yProps(3)}
              sx={{
                textTransform: "capitalize",
                padding: `16px ${rem("32px")}`,
              }}
              className={cn(globalStyles.PrimaryText700, globalStyles.font24)}
            />
            <Tab
              label="5. Review"
              icon={<ReviewIcon aria-label={"review icon"} />}
              {...a11yProps(4)}
              sx={{
                textTransform: "capitalize",
                padding: `16px ${rem("32px")}`,
              }}
              className={cn(globalStyles.PrimaryText700, globalStyles.font24)}
            />
          </Tabs>

          <TabPanel value={value} index={0}>
            <Grid container>
              <Grid item xs={6}>
                <Box className="flex items-center justify-center mt-2">
                  <img
                    src={RequestAvatar}
                    alt="Request avatar"
                    className="w-full h-auto"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="ml-20">
                  <Typography
                    className={cn(
                      globalStyles.PrimaryText700,
                      globalStyles.font48,
                      "mt-2"
                    )}
                  >
                    Tell us what services
                    {"\n"} are you looking for
                  </Typography>
                  <Box className="flex flex-col mt-10">
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Choose a service you need
                      </Typography>
                    </Box>
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Receive confirmation via email and text
                      </Typography>
                    </Box>
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Track request status in real-time
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Grid container>
              <Grid item xs={6}>
                <Box className="flex items-center justify-center mt-2">
                  <img
                    src={MatchAvatar}
                    alt="Match avatar"
                    className="w-full h-auto"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="ml-20">
                  <Typography
                    className={cn(
                      globalStyles.PrimaryText700,
                      globalStyles.font48,
                      "mt-2"
                    )}
                  >
                    Find the right support from a vast pool of support workers
                  </Typography>
                  <Box className="flex flex-col mt-12">
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4 mb-10"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Access to on-demand disability support workers of
                        diverse backgrounds and experience
                      </Typography>
                    </Box>
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4 mb-10"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Match with the best support worker based on personalized
                        needs
                      </Typography>
                    </Box>
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4 mb-10"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Receive notifications when you have a match
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Grid container>
              <Grid item xs={6}>
                <Box className="flex items-center justify-center mt-2">
                  <img
                    src={CommunicateAvatar}
                    alt="Communicate avatar"
                    className="w-full h-auto"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="ml-20">
                  <Typography
                    className={cn(
                      globalStyles.PrimaryText700,
                      globalStyles.font48,
                      "mt-2"
                    )}
                  >
                    Reach out to confirm support details
                  </Typography>
                  <Box className="flex flex-col mt-10">
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Communicate with your support workers in advance
                      </Typography>
                    </Box>
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Set your expectation to ensure a good experience
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={3}>
            <Grid container>
              <Grid item xs={6}>
                <Box className="flex items-center justify-center mt-2">
                  <img
                    src={ServiceAvatar}
                    alt="Service avatar"
                    className="w-full h-auto"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="ml-20">
                  <Typography
                    className={cn(
                      globalStyles.PrimaryText700,
                      globalStyles.font48,
                      "mt-2"
                    )}
                  >
                    Check-in and check-out easily
                  </Typography>
                  <Box className="flex flex-col mt-10">
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Scan a QR code to track the working hours
                      </Typography>
                    </Box>
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Submit timesheet for approval automatically
                      </Typography>
                    </Box>
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Report an incident
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={4}>
            <Grid container>
              <Grid item xs={6}>
                <Box className="flex items-center justify-center mt-2">
                  <img
                    src={ReviewAvatar}
                    alt="Review avatar"
                    className="w-full h-auto"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="ml-20">
                  <Typography
                    className={cn(
                      globalStyles.PrimaryText700,
                      globalStyles.font48,
                      "mt-2"
                    )}
                  >
                    Improve your future experience
                  </Typography>
                  <Box className="flex flex-col mt-10">
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Share your feedback immediately
                      </Typography>
                    </Box>
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Check your customer’s satisfaction
                      </Typography>
                    </Box>
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Submit reimbursement request for faster approval
                      </Typography>
                    </Box>
                    <Box className="flex" style={{ alignItems: "flex-start" }}>
                      <img
                        src={ListArrow}
                        className="mr-4"
                        alt="list arrow"
                        style={{ marginTop: "13px" }}
                      />
                      <Typography
                        className={cn(
                          globalStyles.secondaryText400,
                          globalStyles.font24
                        )}
                      >
                        Generate report automatically
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      ) : (
        <Box className="w-full mt-12 px-3">
          <Accordion
            disableGutters
            expanded={expanded === "panel1"}
            onChange={handleAccordianChange("panel1")}
            sx={
              expanded === "panel1"
                ? { borderBottom: "1px solid #E84B00" }
                : { borderBottom: 0 }
            }
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={
                expanded === "panel1"
                  ? { borderBottom: "1px solid #E84B00" }
                  : { borderBottom: "1px solid #111111" }
              }
            >
              <Typography
                className={cn(globalStyles.PrimaryText700, globalStyles.font17)}
              >
                <span
                  className={cn(
                    expanded === "panel1" ? classes.active : classes.numbers
                  )}
                >
                  01
                </span>
                <span
                  className={expanded === "panel1" ? classes.fontMainColor : ""}
                >
                  Request
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="py-5">
                <Typography
                  className={cn(
                    globalStyles.PrimaryText700,
                    globalStyles.font24,
                    "text-center"
                  )}
                >
                  Tell us which support service you are looking for
                </Typography>
                <Box className="flex flex-col mt-12 px-5">
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Choose a service you need
                    </Typography>
                  </Box>
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Receive confirmation via email and text
                    </Typography>
                  </Box>
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Track request status in real-time
                    </Typography>
                  </Box>
                </Box>
                <img
                  src={RequestAvatar}
                  alt="Interpreter avatar"
                  className="w-full h-auto"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel2"}
            onChange={handleAccordianChange("panel2")}
            sx={
              expanded === "panel2"
                ? { borderBottom: "1px solid #E84B00" }
                : { borderBottom: 0 }
            }
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={
                expanded === "panel2"
                  ? { borderBottom: "1px solid #E84B00" }
                  : { borderBottom: "1px solid #111111" }
              }
            >
              <Typography
                className={cn(
                  globalStyles.PrimaryText700,
                  globalStyles.font17,
                  expanded === "panel2" && classes.fontMainColor
                )}
              >
                <span
                  className={cn(
                    expanded === "panel2" ? classes.active : classes.numbers
                  )}
                >
                  02
                </span>
                <span
                  className={expanded === "panel2" ? classes.fontMainColor : ""}
                >
                  Match
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="py-5">
                <Typography
                  className={cn(
                    globalStyles.PrimaryText700,
                    globalStyles.font24,
                    "text-center"
                  )}
                >
                  Find the right support from a vast pool of support workers
                </Typography>
                <Box className="flex flex-col mt-12 px-5">
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-24"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Access to on-demand disability support workers of diverse
                      backgrounds and experience
                    </Typography>
                  </Box>
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-16"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Match with the best support worker based on personalized
                      needs
                    </Typography>
                  </Box>
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Receive notifications when you have a match
                    </Typography>
                  </Box>
                </Box>
                <img
                  src={MatchAvatar}
                  alt="Interpreter avatar"
                  className="w-full h-auto"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel3"}
            onChange={handleAccordianChange("panel3")}
            sx={
              expanded === "panel3"
                ? { borderBottom: "1px solid #E84B00" }
                : { borderBottom: 0 }
            }
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={
                expanded === "panel3"
                  ? { borderBottom: "1px solid #E84B00" }
                  : { borderBottom: "1px solid #111111" }
              }
            >
              <Typography
                className={cn(globalStyles.PrimaryText700, globalStyles.font17)}
              >
                <span
                  className={cn(
                    expanded === "panel3" ? classes.active : classes.numbers
                  )}
                >
                  03
                </span>
                <span
                  className={expanded === "panel3" ? classes.fontMainColor : ""}
                >
                  Communicate
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="py-5">
                <Typography
                  className={cn(
                    globalStyles.PrimaryText700,
                    globalStyles.font24,
                    "text-center"
                  )}
                >
                  Reach out to confirm support details
                </Typography>
                <Box className="flex flex-col mt-12 px-5">
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Communicate with your support workers in advance
                    </Typography>
                  </Box>
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Set your expectation to ensure a good experience
                    </Typography>
                  </Box>
                </Box>
                <img
                  src={CommunicateAvatar}
                  alt="Interpreter avatar"
                  className="w-full h-auto"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel4"}
            onChange={handleAccordianChange("panel4")}
            sx={
              expanded === "panel4"
                ? { borderBottom: "1px solid #E84B00" }
                : { borderBottom: 0 }
            }
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={
                expanded === "panel4"
                  ? { borderBottom: "1px solid #E84B00" }
                  : { borderBottom: "1px solid #111111" }
              }
            >
              <Typography
                className={cn(globalStyles.PrimaryText700, globalStyles.font17)}
              >
                <span
                  className={cn(
                    expanded === "panel4" ? classes.active : classes.numbers
                  )}
                >
                  04
                </span>
                <span
                  className={expanded === "panel4" ? classes.fontMainColor : ""}
                >
                  Service
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="py-5">
                <Typography
                  className={cn(
                    globalStyles.PrimaryText700,
                    globalStyles.font24,
                    "text-center"
                  )}
                >
                  Check-in and check-out easily
                </Typography>
                <Box className="flex flex-col mt-12 px-5">
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Scan a QR code to track the working hours
                    </Typography>
                  </Box>
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Submit timesheet for approval automatically
                    </Typography>
                  </Box>
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Report an incident
                    </Typography>
                  </Box>
                </Box>
                <img
                  src={ServiceAvatar}
                  alt="Interpreter avatar"
                  className="w-full h-auto"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "panel5"}
            onChange={handleAccordianChange("panel5")}
            sx={
              expanded === "panel5"
                ? { borderBottom: "1px solid #E84B00" }
                : { borderBottom: 0 }
            }
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={
                expanded === "panel5"
                  ? { borderBottom: "1px solid #E84B00" }
                  : { borderBottom: "1px solid #111111" }
              }
            >
              <Typography
                className={cn(globalStyles.PrimaryText700, globalStyles.font17)}
              >
                <span
                  className={cn(
                    expanded === "panel5" ? classes.active : classes.numbers
                  )}
                >
                  05
                </span>
                <span
                  className={expanded === "panel5" ? classes.fontMainColor : ""}
                >
                  Review
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="py-5">
                <Typography
                  className={cn(
                    globalStyles.PrimaryText700,
                    globalStyles.font24,
                    "text-center"
                  )}
                >
                  Improve your future experience
                </Typography>
                <Box className="flex flex-col mt-12 px-5">
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Share your feedback immediately
                    </Typography>
                  </Box>
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Check your customer’s satisfaction
                    </Typography>
                  </Box>
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Submit reimbursement request for faster approval
                    </Typography>
                  </Box>
                  <Box className="flex" style={{ alignItems: "flex-start" }}>
                    <img
                      src={ListArrow}
                      className="mr-4 mb-10"
                      alt="list arrow"
                      style={{ marginTop: "10px" }}
                    />
                    <Typography
                      className={cn(
                        globalStyles.secondaryText400,
                        globalStyles.font24
                      )}
                    >
                      Generate report automatically
                    </Typography>
                  </Box>
                </Box>
                <img
                  src={ReviewAvatar}
                  alt="Interpreter avatar"
                  className="w-full h-auto"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </HowItWorksContainer>
  );
};

export default HowItWorks;
