import React from "react";
import cn from "classnames";
import { Button, Theme, CardActionArea, CardMedia, Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import useGlobalStyles from "theme/useGlobalStyles";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { ServiceInterface } from "components/ServicesOverview/serviceOverview.types";
import { useTheme } from "@mui/system";

const useStyles = makeStyles((theme: Theme) => ({
  cardBackgroundColor: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    marginTop: "10px",
  },
}));

const ReadMoreModal = (props: any) => {
  const theme = useTheme();
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const selectedService: ServiceInterface = { ...props.selectedService };

  return (
    <div className="px-24 py-9 flex items-center text-center">
      <Grid item className={"flex pt-0"}>
        <Card className={cn(classes.cardBackgroundColor)}>
          <CardActionArea>
            <CardMedia style={{ textAlign: "center", marginTop: "20px" }}>
              <img
                src={selectedService.icon}
                alt={selectedService["alt-text"]}
                height={selectedService.height}
                style={{ padding: "10px" }}
                className={"flex-start"}
              />
            </CardMedia>
            <CardContent
              className="direction-col"
              style={{ textAlign: "center" }}
            >
              <Typography
                gutterBottom
                variant="h5"
                className={cn(
                  globalStyles.font24,
                  globalStyles.PrimaryText700,
                  "mx-5"
                )}
                component="div"
              >
                {selectedService.title}
              </Typography>
              <Typography
                variant="body2"
                className={cn(globalStyles.font17, "mx-5")}
                style={{
                  color: theme.palette.text.secondary,
                  textAlign: "left",
                }}
                dangerouslySetInnerHTML={{
                  __html: selectedService.content,
                }}
              />
            </CardContent>
          </CardActionArea>
        </Card>
        <DialogActions>
          <Button
            className={cn(globalStyles.primaryText600, globalStyles.font17)}
            style={{ marginLeft: "auto", marginRight: "auto" }}
            onClick={props.handleModalClose}
          >
            Close
          </Button>
        </DialogActions>
      </Grid>
    </div>
  );
};

export default ReadMoreModal;
