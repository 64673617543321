import { ServiceInterface } from "./serviceOverview.types";

import Sli from "../../assets/svgs/sli.svg";
import CoNavigator from "../../assets/svgs/coNavigator.svg";
import Cf from "../../assets/svgs/cf.svg";

export const ServicesList: Array<ServiceInterface> = [
  {
    id: 1,
    icon: CoNavigator,
    "alt-text": "CoNavigator Logo",
    title: "CoNavigator (CN)",
    height: "90px",
    // width:'79px',
    content:
      "<p>Sometimes called Support Service Provider (SSP), CoNavigator (CN) is one vital tool available for people who are " +
      "DeafBlind and provides support and information for them to lead independent, productive, and quality lives. <br/><br/> A " +
      "CN is an individual trained to act as a link between DeafBlind and their environment. They typically work with a " +
      "single individual and serve as a guide and communication facilitator, relaying visual and environmental " +
      "information. In addition, CNs typically assist DeafBlind people in attending medical appointments, " +
      "participating in public meetings, shopping at the grocery store, and reading mail.</p>",
  },
  {
    id: 2,
    icon: Cf,
    "alt-text": "Communication Facilitator Logo",
    height: "73.32px",
    // width:'88.05px',
    title: "Communication \n" + "Facilitator (CF)",
    content:
      "<p>A communication facilitator (CF) provides visual information to those unable to see the video screen or " +
      "receive visual American Sign Language (ASL).<br/><br/> A CF is a skilled signer who copies sign language from a " +
      "caller, as shown on a videophone screen, and provides visual information to a people through close vision " +
      "or tactile sign language.</p>",
  },
  {
    id: 3,
    icon: Sli,
    "alt-text": "Sign Language Interpretor Logo",
    height: "92px",
    // width: '108px',
    title: "Sign Language \n" + "Interpreter (SLI)",
    content:
      "<p>Sign Language Interpreter (SLI) facilitates communication between hearing and deaf, hard of hearing, " +
      "and DeafBlind individuals in different settings using ASL or other manual sign systems. " +
      "They may interpret for one-on-one communication or public engagements. The responsibilities of a sign " +
      "language interpreter depend on the situation or environment. <br/><br/> Besides helping customers convey and " +
      "receive messages, they may also build rapport with customers to make them feel at ease, manage schedules, " +
      "and perform calls. Sign language interpreters must be certified to provide services in legal, school, " +
      "and hospital settings.</p>",
  },
];
