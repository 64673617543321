import React, { useState } from "react";
import cn from "classnames";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/system";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { Button, useMediaQuery, Grid, Alert } from "@mui/material";
import useGlobalStyles from "../../theme/useGlobalStyles";

import CircularProgress from "@mui/material/CircularProgress";
import { SITE_KEY, lambdaURL } from "../../utils/apis";

import {
  ReCaptcha,
  ACTION,
  EMAIL_REGEX,
  contactusInitValue,
} from "./contactUs.data";

const ContactUsContainer = styled("section")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "100px auto",
  width: "50%",
  padding: "10px",
}));

const ContactUs: React.FC = () => {
  const theme = useTheme();
  const globalStyles = useGlobalStyles();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const placeholderText = "Select a topic";
  let recaptcha = new ReCaptcha(SITE_KEY, ACTION);

  const [formFieldValues, setFormFieldValues]: any = useState({
    ...contactusInitValue,
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType, setAlertType] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formData = { ...formFieldValues };
    formData[e.target.name].value = e.target.value;
    if (formData[e.target.name].required) {
      if (e.target.value === "") {
        formData[e.target.name].error =
          formatErrorMessage(e.target.name) + " is required";
      } else {
        formData[e.target.name].error = null;
      }
      if (e.target.name === "email") {
        if (
          e.target.value !== "" &&
          !String(e.target.value).toLowerCase().match(EMAIL_REGEX)
        ) {
          formData[e.target.name].error =
            "Please provide a valid email address";
        } else if (e.target.value !== "") {
          formData[e.target.name].error = null;
        }
      }
    }
    setFormFieldValues(formData);
  };

  const handleTopicChange = (event: SelectChangeEvent) => {
    let formData = { ...formFieldValues };
    formData.topic.value = event.target.value;
    if (event.target.value === "") {
      formData.topic.error = event.target.name + " is required";
    } else if (event.target.value !== "") {
      formData[event.target.name].error = null;
    }
    setFormFieldValues(formData);
  };

  const checkErrors = (formData: any) => {
    let keys = Object.keys(formData);
    return keys.every((key) => formData[key].error === null);
  };

  const resetFormValues = () => {
    setFormFieldValues({
      "first-Name": {
        value: "",
        error: null,
        required: true,
      },
      "last-Name": {
        value: "",
        error: null,
        required: true,
      },
      email: {
        value: "",
        error: null,
        required: true,
      },
      topic: {
        value: "",
        error: null,
        required: true,
      },
      subject: {
        value: "",
        error: null,
        required: true,
      },
      comment: {
        value: "",
        error: null,
        required: false,
      },
    });
  };

  const formatErrorMessage = (key: string) => {
    return (
      key[0].toUpperCase() + key.substring(1, key.length).replace("-", " ")
    );
  };

  const formatUserData = (formFields: any) => {
    let body: any = {};
    for (let key in formFields) {
      body[key] = formFields[key].value;
    }
    return body;
  };

  const validateInput = async () => {
    setLoading(true);
    let formData = { ...formFieldValues };
    for (let key in formData) {
      if (!formData[key].required) {
        continue;
      } else {
        if (formData[key].value === "") {
          formData[key].error = formatErrorMessage(key) + " is required";
        } else {
          formData[key].error = null;
        }
        if (key === "email") {
          if (
            formData[key].value !== "" &&
            !String(formData[key].value).toLowerCase().match(EMAIL_REGEX)
          ) {
            formData[key].error = "Please provide a valid email address";
          } else if (formData[key].value !== "") {
            formData[key].error = null;
          }
        }
      }
    }

    let check = checkErrors(formData);
    if (check) {
      let token: string = await recaptcha.getToken();
      await validateRecaptcha(token);
    } else {
      setFormFieldValues(formData);
      setLoading(false);
    }
  };

  const validateRecaptcha = async (recaptchaToken: string): Promise<any> => {
    let body = {
      token: recaptchaToken,
      userInformation: formatUserData(formFieldValues),
    };

    await fetch(lambdaURL, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data: any) => {
        if (data.data.statusCode === 201) {
          setLoading(false);
          setAlert(true);
          setAlertType("success");
          setAlertMsg(data.data.msg);
          resetFormValues();
        } else {
          setLoading(false);
          setAlert(true);
          setAlertType("error");
          setAlertMsg(data.data.msg);
          resetFormValues();
        }
      })
      .catch(() => {
        resetFormValues();
        setAlert(true);
        setAlertType("error");
        setAlertMsg("Something went wrong !!! Please contact support");
        setLoading(false);
      });
  };

  return (
    <ContactUsContainer id="contact-us-section" className={"contact-us-mobile"}>
      <div className="px-5 py-5 flex items-center text-center">
        <h1
          className={
            isMatch
              ? cn(globalStyles.PrimaryText700, globalStyles.font48)
              : cn(globalStyles.PrimaryText700, globalStyles.font48)
          }
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          Contact us
        </h1>
      </div>
      <div id={"contact-us-form"}>
        <Box component="form" noValidate autoComplete="off">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={"form-inputs-mobile"}>
                <div className={"mb-2"}>
                  <label
                    className={"mb-2"}
                    htmlFor={"first_name"}
                    aria-label={"First Name"}
                  >
                    First Name
                  </label>
                  <span>*</span>
                  <TextField
                    id={"first_name"}
                    name="first-Name"
                    aria-required={true}
                    aria-label={"Enter First Name"}
                    role={"textbox"}
                    value={formFieldValues["first-Name"].value}
                    onChange={handleInputChange}
                    placeholder={"Jane"}
                    style={{ width: "100%" }}
                    className={"mb-1 mt-2"}
                  />
                </div>
                {formFieldValues["first-Name"].error && (
                  <div style={{ color: "red" }}>
                    {formFieldValues["first-Name"].error}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={"form-inputs-mobile"}>
                <div className={"mb-2"}>
                  <label
                    className={"mb-2"}
                    htmlFor={"last_name"}
                    aria-label={"Last Name"}
                  >
                    Last Name
                  </label>
                  <span>*</span>
                  <TextField
                    id={"last_name"}
                    aria-label={"Last Name"}
                    aria-required={true}
                    role={"textbox"}
                    name="last-Name"
                    value={formFieldValues["last-Name"].value}
                    onChange={handleInputChange}
                    placeholder={"Doe"}
                    style={{ width: "100%" }}
                    className={"mb-1 mt-2"}
                  />
                </div>
                {formFieldValues["last-Name"].error && (
                  <div style={{ color: "red" }}>
                    {formFieldValues["last-Name"].error}
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className={"form-inputs-mobile"}
              >
                <div className={"mb-2"}>
                  <label
                    className={"mb-2"}
                    htmlFor={"email"}
                    aria-label={"Email"}
                  >
                    Email
                  </label>
                  <span>*</span>
                  <TextField
                    id={"email"}
                    aria-label={"Email"}
                    aria-required={true}
                    role={"textbox"}
                    name="email"
                    value={formFieldValues["email"].value}
                    onChange={handleInputChange}
                    placeholder={"Jane.doe@mizaru.com"}
                    style={{ width: "100%" }}
                    className={"mb-1 mt-2"}
                  />
                </div>
                {formFieldValues["email"].error && (
                  <div style={{ color: "red" }}>
                    {formFieldValues["email"].error}
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className={"form-inputs-mobile"}
              >
                <div className={"mb-2"}>
                  <label
                    className={"mb-2"}
                    htmlFor={"topic"}
                    aria-label={"Topic"}
                  >
                    Topic
                  </label>
                  <span>*</span>
                  <Select
                    id={"topic"}
                    aria-label={"Topic"}
                    aria-required={true}
                    role={"listbox"}
                    labelId="select-helper-label"
                    name="topic"
                    value={formFieldValues["topic"].value}
                    placeholder={placeholderText}
                    onChange={handleTopicChange}
                    displayEmpty
                    style={{
                      width: "100%",
                      color:
                        formFieldValues["topic"].value === ""
                          ? "#00000099"
                          : "#111111",
                    }}
                    className={"mb-1 mt-2"}
                  >
                    <MenuItem value={""} disabled={true} role="option">
                      Select a topic
                    </MenuItem>
                    <MenuItem
                      value={"Billing Question"}
                      aria-label={"Billing Question"}
                      role="option"
                    >
                      Billing Question
                    </MenuItem>
                    <MenuItem
                        value={"Feedback & Suggestion"}
                        aria-label={"Feedback & Suggestion"}
                        role="option"
                    >
                      Feedback & Suggestion
                    </MenuItem>
                    <MenuItem
                      value={"Incident Report"}
                      aria-label={"Incident Report"}
                      role="option"
                    >
                      Incident Report
                    </MenuItem>
                    <MenuItem
                      value={"Profile Update"}
                      aria-label={"Profile Update"}
                      role="option"
                    >
                      Profile Update
                    </MenuItem>
                    <MenuItem
                      value={"Technical Issue"}
                      aria-label={"Technical Issue"}
                      role="option"
                    >
                      Technical Issue
                    </MenuItem>
                    <MenuItem
                      value={"Others"}
                      aria-label={"Others"}
                      role="option"
                    >
                      Others
                    </MenuItem>
                  </Select>
                </div>
                {formFieldValues["topic"].error && (
                  <div style={{ color: "red" }}>
                    {formFieldValues["topic"].error}
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className={"form-inputs-mobile"}
              >
                <div className={"mb-2"}>
                  <label
                    className={"mb-2"}
                    htmlFor={"subject"}
                    aria-label={"Subject"}
                  >
                    Subject
                  </label>
                  <span>*</span>
                  <TextField
                    id={"subject"}
                    aria-label={"Subject"}
                    aria-required={true}
                    role={"textbox"}
                    name="subject"
                    value={formFieldValues["subject"].value}
                    onChange={handleInputChange}
                    placeholder={"Enter your subject"}
                    style={{ width: "100%" }}
                    className={"mb-1 mt-2"}
                  />
                </div>
                {formFieldValues["subject"].error && (
                  <div style={{ color: "red" }}>
                    {formFieldValues["subject"].error}
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className={"form-inputs-mobile"}
              >
                <div className={"mb-2"}>
                  <label
                    className={"mb-2"}
                    htmlFor={"comments"}
                    aria-label={"Comments"}
                  >
                    Comments
                  </label>
                  <TextField
                    id={"comments"}
                    aria-label={"Comments"}
                    role={"textbox"}
                    name="comment"
                    value={formFieldValues["comment"].value}
                    onChange={handleInputChange}
                    style={{ width: "100%" }}
                    rows={4}
                    multiline
                    placeholder={"Enter your comments"}
                    className={"mb-1 mt-2"}
                  />
                </div>

                {formFieldValues["comment"].error && (
                  <div style={{ color: "red" }}>
                    {formFieldValues["comment"].error}
                  </div>
                )}
              </Grid>
              <Grid item xs={4} className={"form-inputs-mobile"}>
                <Button
                  id={"submitButton"}
                  disabled={loading}
                  className={"form-inputs-mobile"}
                  onClick={validateInput}
                  aria-label={"Submit Button"}
                  style={{ color: "black", border: "1px solid black" }}
                >
                  {loading ? "Submitting ..." : "Submit"}
                  {loading && <CircularProgress size={24} className={"ml-5"} />}
                </Button>
              </Grid>
              <Grid item xs={12} md={8} lg={8} className={"form-inputs-mobile"}>
                {alert ? (
                  <Alert
                    onClose={() => setAlert(false)}
                    severity={alertType === "success" ? "success" : "error"}
                  >
                    {alertMsg}
                  </Alert>
                ) : null}
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </ContactUsContainer>
  );
};

export default ContactUs;
