import React from "react";
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import cn from "classnames";
import useGlobalStyles from "theme/useGlobalStyles";
import brandLogo from "assets/images/favicon_mizaru.png";
import Close from "@mui/icons-material/Close";
import { MenuProps } from "./menu.types";
import { Link, useNavigate } from "react-router-dom";
import { Link as ReactScrollLink } from "react-scroll";
import { Buttonslabel } from "components/Navbar";
import { openInNewTab } from "utils";
import { MIZARU_APP_URL } from "utils/constants";

const Menu: React.FC<MenuProps> = ({ open, setOpen }) => {
  const globalStyles = useGlobalStyles();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      className="mobileDrw"
      open={open}
      onClose={handleClose}
    >
      <Box className="direction-row padding-15">
        <Grid className="flex-grow center" item xs={10}>
          <Link to="/">
            <img src={brandLogo} alt="mizaru-logo" width="120px" />
          </Link>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-black" />
          </IconButton>
        </Grid>
      </Box>
      <List className="mobile_nav">
        <Box className="direction-col p-15 text-black">
          {Buttonslabel.map((label: string, index: number) => (
            <ReactScrollLink
              key={index}
              delay={100}
              offset={-120}
              to={`${label.toLowerCase().replaceAll(" ", "-")}-section`}
              spy={true}
              smooth={true}
              onClick={handleClose}
            >
              <ListItemIcon
                key={index}
                sx={{
                  "&:hover": {
                    backgroundColor: "#FFE8EA",
                    padding: "8px",
                    cursor: "pointer",
                    borderRadius: "5px",
                  },
                }}

              >
                <ListItemText className="text-black font-500">
                  {label}
                </ListItemText>
              </ListItemIcon>
            </ReactScrollLink>
          ))}
        </Box>
      </List>
      <Box className="direction-col">
        <Button
          className={cn(
            globalStyles.primaryText600,
            globalStyles.font17,
            "padding_button",
            "text-white"
          )}
          aria-label={"Login Button"}
          variant="contained"
          color="primary"
          onClick={() => {
            handleClose();
            return openInNewTab(MIZARU_APP_URL);
          }}
        >
          Login
        </Button>
        <Button
          className={cn(
            globalStyles.primaryText600,
            globalStyles.font17,
            "padding_button "
          )}
          aria-label={"Signup Button"}
          variant="outlined"
          sx={{ borderColor: "black" }}
          onClick={() => {
            handleClose();
            navigate("/signup");
          }}
        >
          Sign Up
        </Button>
      </Box>
    </Drawer>
  );
};

export default Menu;
