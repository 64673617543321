import React from "react";

import { Route, Routes, HashRouter } from "react-router-dom";

import Home from "components/Home";
import Signup from "components/Signup";
import Redirection from "./components/Redirection";
import Layout from "Layout";

const App: React.FC = () => {
  return (
    <HashRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="signup" element={<Signup />} />
          <Route path="login" element={<Redirection/>} />
        </Routes>
      </Layout>
    </HashRouter>
  );
};

export default App;
