import React from "react";

import cn from "classnames";

import {
  Button,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import FAQAccordian from "components/FAQAccordian";

import useGlobalStyles from "theme/useGlobalStyles";
import { rem } from "polished";
import {
  LOOKING_FOR_SUPPORT_URL,
  SUPPORT_PROVIDER_URL,
} from "../../utils/constants";

const FAQsContainer = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "80%",
  margin: "80px auto",

  [theme.breakpoints.down("sm")]: {
    margin: "36px auto",
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  switchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: rem("8px"),
    border: "1px solid #11111166",
    zIndex: "4",
    maxHeight: rem("130px"),
    marginBottom: rem("120px"),
    "&:first-child": {
      boxShadow: "2px 0px 10px 0px #0000001A",
    },

    "&:last-child": {
      boxShadow: "-2px 0px 10px 0px #0000001A",
      borderRadius: `0 ${rem("8px")} ${rem("8px")} 0`,
    },
  },
  switchButton: {
    padding: `${rem("40px")} ${rem("90px")}`,
    maxHeight: rem("120px"),
    zIndex: "1",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  active: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    maxHeight: rem("120px"),
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },

    "&:first-child": {
      boxShadow: "2px 0px 10px 0px #0000001A",
      borderRadius: `${rem("8px")} 0 0 ${rem("8px")}`,
    },

    "&:last-child": {
      boxShadow: "-2px 0px 10px 0px #0000001A",
      borderRadius: ` 0 ${rem("8px")} ${rem("8px")} 0`,
    },
  },

  resSwitchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: 0,
    marginBottom: rem("36px"),
    "&:hover": {
      backgroundColor: "none",
    },
  },
  resButton: {
    width: "100%",
    borderRadius: 0,
    padding: `${rem("20px")} ${rem("50px")}`,
    "&:hover": {
      backgroundColor: "none",
    },
  },
  resActive: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.common.black,
    },
  },
}));

const FAQs: React.FC = () => {
  const globalStyles = useGlobalStyles();
  const classes = useStyles();
  const [active, setActive] = React.useState(0);
  const isMatch = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <FAQsContainer id="faqs-section">
      {!isMatch ? (
        <div className={classes.switchContainer}>
          <Button
            className={cn(classes.switchButton, active === 0 && classes.active)}
            onClick={() => setActive(0)}
          >
            <Typography
              className={cn(globalStyles.PrimaryText700, globalStyles.font24)}
            >
              Looking for support
            </Typography>
          </Button>
          <Button
            className={cn(classes.switchButton, active === 1 && classes.active)}
            onClick={() => setActive(1)}
          >
            <Typography
              className={cn(globalStyles.PrimaryText700, globalStyles.font24)}
            >
              Providing support
            </Typography>
          </Button>
        </div>
      ) : (
        <div className={classes.resSwitchContainer}>
          <Button
            className={cn(classes.resButton, active === 0 && classes.resActive)}
            onClick={() => setActive(0)}
          >
            <Typography
              className={cn(
                globalStyles.PrimaryText700,
                globalStyles.font24,
                active === 0 && "text-white"
              )}
            >
              Looking for support
            </Typography>
          </Button>
          <Button
            className={cn(classes.resButton, active === 1 && classes.resActive)}
            onClick={() => setActive(1)}
          >
            <Typography
              className={cn(
                globalStyles.PrimaryText700,
                globalStyles.font24,
                active === 1 && "text-white"
              )}
            >
              Providing support
            </Typography>
          </Button>
        </div>
      )}

      {active === 0 && (
        <React.Fragment>
          <Typography
            className={cn(
              globalStyles.PrimaryText700,
              globalStyles.font48,
              "text-center",
              "mb-6"
            )}
          >
            Access on-demand disability support workers of diverse backgrounds
            and experience
          </Typography>
          <Typography
            className={cn(
              globalStyles.secondaryText400,
              globalStyles.font24,
              "text-center",
              "mb-16"
            )}
          >
            Find and manage your support workers on a single platform and make
            your products and services more accessible to people with
            disabilities
            {/*Join the platform with the largest network of active freelance*/}
            {/*support workers to support people with disabilities anytime,*/}
            {/*anywhere*/}
          </Typography>
          <a
            href={LOOKING_FOR_SUPPORT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="contained" color="primary" className="text-white">
              I’m looking for support
            </Button>
          </a>
        </React.Fragment>
      )}
      {active === 1 && (
        <React.Fragment>
          <Typography
            className={cn(
              globalStyles.PrimaryText700,
              globalStyles.font48,
              "text-center",
              "mb-6"
            )}
          >
            Provide disability support services and get paid
          </Typography>
          <Typography
            className={cn(
              globalStyles.secondaryText400,
              globalStyles.font24,
              "text-center",
              "mb-16"
            )}
          >
            Join the platform with the largest network of active freelance
            support workers to support people with disabilities anytime,
            anywhere
          </Typography>
          <a
            href={SUPPORT_PROVIDER_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="contained" color="primary" className="text-white">
              I want to provide support
            </Button>
          </a>
        </React.Fragment>
      )}
      <FAQAccordian page={active} />
    </FAQsContainer>
  );
};

export default FAQs;
